import { FC, SyntheticEvent, memo, useEffect, useState } from 'react';

import { useRouter } from 'next/router';

import { Autocomplete, TextField } from '@mui/material';

import { useDebounce } from '@hooks_common/useDebounce';

import { getMansionOptions } from '@hooks_iqfu/public/useMansionData';

import StarCounts from '@components_iqfu/public/star_counts/StarCounts';

import { MansionSearchOptions } from '@type-def_iqfu/public/MansionSearchOptions';

import styles from '../CardCommon.module.scss';

type Props = {
  pageLabel: string;
  searchOption: MansionSearchOptions;
};

const MansionCard: FC<Props> = memo(({ pageLabel, searchOption }) => {
  return (
    <>
      <div className={`${styles.card}`}>
        <div className={styles.card_header}>
          <div className={styles.card_header_icon}>
            <img src="/images/iqfu/public/check_price/mansion_icon.svg" alt="" loading="lazy" />
          </div>
          <h3 className={styles.card_header_lead}>
            <span className={styles.card_header_lead_first}>3秒でわかる！</span>
            <br />
            <span className={styles.card_header_lead_second}>
              {searchOption.area === 'prefecture' ? `${searchOption.prefecture?.name}の` : ''}
              【マンション名】から
            </span>
            <br />
            <span className={styles.card_header_lead_third}>売却価格・査定額・相場を調べる</span>
          </h3>
        </div>
        <div className={styles.time_info_wrap}>
          <dl className={styles.time_info}>
            <dt className={styles.time_info_title}>所要時間</dt>
            <dd className={styles.time_info_content}>最短3秒</dd>
          </dl>
          <dl className={styles.time_info}>
            <dt className={styles.time_info_title}>査定精度</dt>
            <dd className={styles.time_info_content}>
              <StarCounts rating={3} parentComponent={'CheckPriceCard'} />
            </dd>
          </dl>
        </div>
        <div>
          <div className={styles.searchInput_container}>
            <SearchByMansionNameInput pageLabel={pageLabel} searchOption={searchOption} />
          </div>
        </div>
      </div>
    </>
  );
});

MansionCard.displayName = 'MansionCard';

const SearchByMansionNameInput: FC<Props> = memo(({ pageLabel, searchOption }) => {
  const router = useRouter();
  //mansion autoComplete
  const [mansionId, setMansionId] = useState(0);
  const [mansionNameInputVal, setMansionNameInputVal] = useState('');

  const [mansionOptions, setMansionOptions] = useState<{ label: string; id: string }[]>();
  const debouncedMansionNameInput = useDebounce(mansionNameInputVal, 500);
  const handleMansionSelect = (newValue: { label: string; id: string } | null) => {
    setMansionId(Number(newValue?.id));
    router.prefetch(`/mansions/${newValue?.id}`);
  };

  const handleMansionIncrementalSearch = (inputVal: string) => {
    setMansionNameInputVal(inputVal);
  };

  const fetchMansionOptions = async () => {
    if (mansionNameInputVal.length) {
      const mansionNameQuery = mansionNameInputVal;

      //関数内利用
      const fetchAutoComplete = (async (mansionNameQuery: string) => {
        const { mansions } = await getMansionOptions({
          q: mansionNameQuery,
          prefecture: searchOption.area === 'prefecture' ? searchOption.prefecture?.code : undefined,
        });
        const tempMansionOptions: { label: string; id: string }[] = [];
        mansions.map((mansion: { id: number; name: string; address_summary: string }) => {
          tempMansionOptions.push({ label: mansion.name, id: String(mansion.id) });
        });
        setMansionOptions(tempMansionOptions);
      })(mansionNameQuery);
    }
  };

  useEffect(() => {
    if (debouncedMansionNameInput.length) fetchMansionOptions();
  }, [debouncedMansionNameInput]);

  const searchMansionHandler = () => {
    router.push(`/mansions/${mansionId}`);
  };

  return (
    <div className={styles.search_by_mansion_name}>
      <label className={`${styles.input_item_label}`}>マンション名</label>
      <div>
        <Autocomplete
          className="autoComplete_input"
          inputValue={mansionNameInputVal}
          onChange={(event: SyntheticEvent<Element, Event>, newValue: { label: string; id: string } | null) => {
            handleMansionSelect(newValue);
          }}
          onInputChange={(e, newInputValue) => handleMansionIncrementalSearch(newInputValue)}
          renderInput={(params) => <TextField {...params} placeholder="マンション名で検索" />}
          options={mansionOptions || []}
          noOptionsText={mansionNameInputVal.length < 1 ? '入力して下さい' : '該当なし'}
        />
        <button
          onClick={() => {
            searchMansionHandler();
          }}
          className={`${styles.search_mansion_button} ${mansionId ? '' : styles.button_disabled}`}
        >
          実際に売れた金額を調べる
        </button>
      </div>
    </div>
  );
});

export default MansionCard;

SearchByMansionNameInput.displayName = 'SearchByMansionNameInput';
