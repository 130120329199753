import { FC, SyntheticEvent, memo, useEffect, useState } from 'react';

import { useRouter } from 'next/router';

import { Autocomplete, TextField, useMediaQuery } from '@mui/material';

import { useQuery } from 'react-query';

import { useAddress } from '@hooks_common/useAddress';

import StarCounts from '@components_iqfu/public/star_counts/StarCounts';

import { AutoCompleteInput } from '@type-def_common/AutoCompleteInput';

import { MediaQuery } from '@constants_common';
import { Prefectures } from '@constants_iqfu';

import styles from '../CardCommon.module.scss';

type Props = {
  pageLabel: string;
};

const LandHouseCard: FC<Props> = memo(({ pageLabel }) => {
  return (
    <div className={styles.card}>
      <div className={styles.card_header}>
        <div className={styles.card_header_icon}>
          <img src="/images/iqfu/public/check_price/landhouses_icon.svg" alt="" loading="lazy" />
        </div>
        <h3 className={styles.card_header_lead}>
          <span className={styles.card_header_lead_first}>3秒でわかる！全国対応！</span>
          <br />
          <span className={styles.card_header_lead_second}>各地域の土地・一戸建ての</span>
          <br />
          <span className={styles.card_header_lead_third}>売却価格・査定額・相場を調べる</span>
        </h3>
      </div>
      <div className={styles.time_info_wrap}>
        <dl className={styles.time_info}>
          <dt className={styles.time_info_title}>所要時間</dt>
          <dd className={styles.time_info_content}>最短3秒</dd>
        </dl>
        <dl className={styles.time_info}>
          <dt className={styles.time_info_title}>査定精度</dt>
          <dd className={styles.time_info_content}>
            <StarCounts rating={3} parentComponent={'CheckPriceCard'} />
          </dd>
        </dl>
      </div>
      <div>
        <div className={styles.searchInput_container}>
          <SearchLandHousesByArea pageLabel={pageLabel} />
        </div>
      </div>
    </div>
  );
});

LandHouseCard.displayName = 'LandHouseCard';

const SearchLandHousesByArea: FC<Props> = memo(({}) => {
  const router = useRouter();
  const isMobile = useMediaQuery(MediaQuery.lg);
  const { getCities, getTowns } = useAddress();
  const prefectureOptions = Object.entries(Prefectures).map(([id, label]) => ({
    id: id,
    label,
  }));

  const [citiesOptions, setCitiesOptions] = useState<{ label: string; id: string }[]>([]);
  const [townsOptions, setTownsOptions] = useState<{ label: string; id: string }[]>([]);

  const [prefecture, setPrefecture] = useState<AutoCompleteInput>(null);
  const [city, setCity] = useState<AutoCompleteInput>(null);
  const [town, setTown] = useState<AutoCompleteInput>(null);
  const [prefectureInputVal, setPrefectureInputVal] = useState<string>('');
  const [cityInputVal, setCityInputVal] = useState<string>('');
  const [townInputVal, setTownInputVal] = useState<string>('');

  const { data: fetechedCitiesOptions } = useQuery(
    ['fetechedCitiesOptions', prefecture?.id],
    async () => {
      if (!prefecture?.id) return;
      const { cities } = await getCities(prefecture.id);
      let result: { label: string; id: string }[] = [];
      cities.map((city: { code: string; name: string }) => {
        result.push({ label: city.name, id: String(city.code) });
      });
      return result;
    },
    {
      enabled: !!prefecture?.id,
      staleTime: 1000 * 60 * 30,
    },
  );

  useEffect(() => {
    if (fetechedCitiesOptions) setCitiesOptions(fetechedCitiesOptions);
  }, [fetechedCitiesOptions]);

  const { data: fetechedTownsOptions } = useQuery(
    ['fetechedTownsOptions', city?.id],
    async () => {
      if (!city?.id) return;
      const { towns } = await getTowns(city.id);
      let result: { label: string; id: string }[] = [];
      towns.map((city: { code: string; name: string }) => {
        result.push({ label: city.name, id: String(city.code) });
      });
      return result;
    },
    {
      enabled: !!city?.id,
      staleTime: 1000 * 60 * 30,
    },
  );

  useEffect(() => {
    if (fetechedTownsOptions) setTownsOptions(fetechedTownsOptions);
  }, [fetechedTownsOptions]);

  const handlePrefectureChange = async (value: AutoCompleteInput): Promise<void> => {
    if (!value) return;
    setCitiesOptions([]);
    setCity(null);
    setCityInputVal('');
    setTownsOptions([]);
    setTown(null);
    setTownInputVal('');

    setPrefecture(value);
    router.prefetch(`/landhouses/prefectures/${value.id}`);
  };

  const handleCityChange = async (value: AutoCompleteInput): Promise<void> => {
    if (!value) return;
    setTownsOptions([]);
    setTown(null);
    setTownInputVal('');
    setCity(value);
    router.prefetch(`/landhouses/cities/${value.id}`);
  };

  const handleTownChange = async (value: AutoCompleteInput): Promise<void> => {
    if (!value) return;
    setTown(value);
    router.prefetch(`/landhouses/${value.id}`);
  };

  const dispatchSearchHandler = () => {
    if (prefecture && city && town) {
      router.push(`/landhouses/${town.id}`);
    } else if (prefecture && city) {
      router.push(`/landhouses/cities/${city.id}`);
    } else if (prefecture) {
      router.push(`/landhouses/prefectures/${prefecture.id}`);
    }
  };

  return (
    <div className={`${styles.search_landhouses_by_area}`}>
      <div className={`${styles.search_landhouses_by_area_input_area}`}>
        <div className={styles.input_item_stack}>
          <label className={styles.input_item_label}>都道府県</label>
          <Autocomplete
            value={prefecture}
            inputValue={prefectureInputVal}
            onChange={(event: SyntheticEvent<Element, Event>, newValue: AutoCompleteInput) => {
              handlePrefectureChange(newValue);
            }}
            onInputChange={(e, newInputValue) => setPrefectureInputVal(newInputValue)}
            renderInput={({ inputProps, ...otherParams }) => (
              <TextField
                {...otherParams}
                autoComplete="off"
                //pleceholder="都道府県 or 市区町村"などにするとchromeの自動補完が発動してしまい、不便になる
                placeholder="選択"
                inputProps={{ ...inputProps, readOnly: isMobile }}
              />
            )}
            options={prefectureOptions}
            noOptionsText={'該当なし'}
          />
        </div>
        <div className={styles.input_item_stack}>
          <label className={styles.input_item_label}>市区町村</label>
          <Autocomplete
            value={city}
            inputValue={cityInputVal}
            onChange={(event: SyntheticEvent<Element, Event>, newValue: AutoCompleteInput) => {
              handleCityChange(newValue);
            }}
            onInputChange={(e, newInputValue) => setCityInputVal(newInputValue)}
            renderInput={({ inputProps, ...otherParams }) => (
              <TextField
                {...otherParams}
                autoComplete="off"
                //pleceholder="都道府県 or 市区町村"などにするとchromeの自動補完が発動してしまい、不便になる
                placeholder="選択"
                inputProps={{ ...inputProps, readOnly: isMobile }}
              />
            )}
            options={citiesOptions}
            noOptionsText={'該当なし'}
          />
        </div>

        <div className={`${styles.input_item_stack}`}>
          <label className={styles.input_item_label} htmlFor="town-select">
            町域
          </label>
          <Autocomplete
            className={'no_icon'}
            value={town}
            inputValue={townInputVal}
            onChange={(event: SyntheticEvent<Element, Event>, newValue: AutoCompleteInput) => {
              handleTownChange(newValue);
            }}
            onInputChange={(e, newInputValue) => {
              setTownInputVal(newInputValue);
            }}
            renderInput={({ inputProps, ...otherParams }) => (
              <TextField
                {...otherParams}
                autoComplete="off"
                //pleceholder="都道府県 or 市区町村"などにするとchromeの自動補完が発動してしまい、不便になる
                placeholder="例）日本橋一丁目"
                inputProps={{ ...inputProps }}
              />
            )}
            options={townsOptions}
            noOptionsText={'該当なし'}
            id="town-select"
          />
        </div>
      </div>
      <button
        onClick={() => {
          dispatchSearchHandler();
        }}
        className={`${styles.search_landhouses_button} ${prefecture ? '' : styles.button_disabled}`}
      >
        実際に売れた金額を調べる
      </button>
    </div>
  );
});

export default LandHouseCard;

SearchLandHousesByArea.displayName = 'SearchLandHousesByArea';
