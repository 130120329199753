import { FC, memo } from 'react';

import Link from 'next/link';

import StarCounts from '@components_iqfu/public/star_counts/StarCounts';

import styles from '../CardCommon.module.scss';

type Props = {
  pageLabel: string;
  hasMansionLink?: boolean;
  hasHouseLink?: boolean;
  hasLandLink?: boolean;
  locationPropertyText?: string;
  prefectureCode?: string;
  cityCode?: string;
  townCode?: string;
  mansionId?: string;
};

const SimulatorCard: FC<Props> = memo(
  ({
    hasMansionLink = true,
    hasHouseLink = true,
    hasLandLink = true,
    pageLabel,
    locationPropertyText = '全国の土地・中古戸建・<br />マンションの',
    prefectureCode,
    cityCode,
    townCode,
    mansionId,
  }) => {
    const simulatorUrl = (propertyType: string): string => {
      let url: string = `/simulator?property_type=${propertyType}`;
      if (townCode) {
        url = url + `&town_code=${townCode}`;
      } else if (cityCode) {
        url = url + `&city_code=${cityCode}`;
      } else if (prefectureCode) {
        url = url + `&prefecture_code=${prefectureCode}`;
      } else if (mansionId) {
        url = url + `&mansion_id=${mansionId}`;
      }
      return url;
    };

    return (
      <div className={styles.card}>
        <div className={styles.card_header}>
          <div className={styles.card_header_icon_simulator}>
            <img src="/images/iqfu/public/check_price/simulator_icon.svg" alt="" loading="lazy" />
          </div>
          <h3 className={styles.card_header_lead}>
            <span className={styles.card_header_lead_first}>個人情報入力なし！</span>
            <br />
            <span
              className={`${styles.card_header_lead_second}`}
              dangerouslySetInnerHTML={{
                __html: locationPropertyText,
              }}
            ></span>
            <span className={styles.card_header_lead_third}>半年以内に売れる価格を試算</span>
          </h3>
        </div>
        <div className={styles.card_body}>
          <div className={styles.time_info_wrap}>
            <dl className={styles.time_info}>
              <dt className={styles.time_info_title}>所要時間</dt>
              <dd className={styles.time_info_content}>最短30秒</dd>
            </dl>
            <dl className={styles.time_info}>
              <dt className={styles.time_info_title}>査定精度</dt>
              <dd className={styles.time_info_content}>
                <StarCounts rating={4} parentComponent={'CheckPriceCard'} />
              </dd>
            </dl>
          </div>
          <div className={`${styles.simulator_buttons_container}`}>
            {hasLandLink && (
              <Link href={simulatorUrl('land')} prefetch={false} className={`${styles.search_simulator_button}`}>
                土地の価格を試算
              </Link>
            )}
            {hasHouseLink && (
              <Link href={simulatorUrl('house')} prefetch={false} className={`${styles.search_simulator_button}`}>
                戸建ての価格を試算
              </Link>
            )}
            {hasMansionLink && (
              <Link href={simulatorUrl('mansion')} prefetch={false} className={`${styles.search_simulator_button}`}>
                マンションの価格を試算
              </Link>
            )}
          </div>
        </div>
      </div>
    );
  },
);

SimulatorCard.displayName = 'SimulatorCard';

export default SimulatorCard;
