import { FC } from 'react';

import StarIcon from '/public/images/iqfu/public/star.svg';

import styles from './StarCounts.module.scss';

type ParentComponent = 'CheckPriceCard' | 'UsersVoice';

type StarCountsProps = {
  rating: number;
  parentComponent: ParentComponent;
};

const StarCounts: FC<StarCountsProps> = ({ rating, parentComponent }) => {
  return (
    <div
      className={`${styles.star_counts_container} ${
        parentComponent === 'CheckPriceCard' ? styles.check_price_card : ''
      }`}
    >
      {[...Array(rating)].map((_, index) => {
        return (
          <div key={index} className={styles.star_icon_wrap}>
            <StarIcon key={index} />
          </div>
        );
      })}
      {[...Array(5 - rating)].map((_, index) => {
        return (
          <div key={index} className={`${styles.star_icon_wrap} ${styles.star_icon_wrap_gray}`}>
            <StarIcon key={index} />
          </div>
        );
      })}
    </div>
  );
};

export default StarCounts;
