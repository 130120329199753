import { FC } from 'react';

import LandHouseCard from '@components_iqfu/public/check_price/landhouse_card/LandHouseCard';
import MansionCard from '@components_iqfu/public/check_price/mansion_card/MansionCard';
import SimulatorCard from '@components_iqfu/public/check_price/simulator_card/SimulatorCard';
import SectionLeadLeftAlign from '@components_iqfu/public/section_lead_left_align/SectionLeadLeftAlign';

import { MansionSearchOptions } from '@type-def_iqfu/public/MansionSearchOptions';

import styles from './CheckPrice.module.scss';

type Props = {
  pageLabel: string;
  searchOption: MansionSearchOptions;
  prefectureCode?: string;
  cityCode?: string;
};

const CheckPrice: FC<Props> = ({ pageLabel, searchOption, prefectureCode, cityCode }) => {
  return (
    <>
      <div className={styles.checkPrice_container}>
        <div className={styles.title}>
          <SectionLeadLeftAlign>
            自分で簡単に匿名で
            <br className="sp_only" />
            「物件の売却価格を調べる」
          </SectionLeadLeftAlign>
        </div>

        <div className={styles.stacks_wrapper}>
          <div className={styles.card_container}>
            <SimulatorCard pageLabel={pageLabel} prefectureCode={prefectureCode} cityCode={cityCode} />
          </div>
          <div className={styles.card_container}>
            <LandHouseCard pageLabel={pageLabel} />
          </div>
          <div className={styles.card_container}>
            <MansionCard pageLabel={pageLabel} searchOption={searchOption} />
          </div>
        </div>
      </div>

      {/* <div className={styles.contact_container}>
        <div className={styles.title}>
          {isMobile ? (
            <SectionLeadLeftAlign>お悩みのご相談を承ります</SectionLeadLeftAlign>
          ) : (
            <SectionLead>お悩みのご相談を承ります</SectionLead>
          )}
        </div>
        <ContactCard pageLabel={pageLabel} />
      </div> */}
    </>
  );
};

export default CheckPrice;
