import * as Sentry from '@sentry/browser';

const backendBasePath = process.env.NEXT_PUBLIC_DEV_BACKEND_URL;

const queryString = require('query-string');

//getStaticProps内で使うのでhooksにしない
export const getMansionDetail = async (mansionId: number) => {
  const url = `${backendBasePath}/mansions/${mansionId}`;

  const result = await fetch(url, {
    method: 'GET',
    mode: 'cors',
  })
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      Sentry.captureException(error);
      console.error('通信に失敗しました', error);
    });
  return result;
};

type MansionOptionsQuery = {
  q?: string;
  prefecture?: number;
  city_code?: number;
};

type MansionOptionsData = {
  mansions: {
    id: number;
    name: string;
    address_summary: string;
  }[];
};

//getStaticProps内で使うのでhooksにしない
export const getMansionOptions = async (mansionOptionsQuery: MansionOptionsQuery): Promise<MansionOptionsData> => {
  const query = queryString.stringify(mansionOptionsQuery);
  const url = `${backendBasePath}/mansions/options?${query}`;

  const result = await fetch(url, {
    method: 'GET',
    mode: 'cors',
  })
    .then((response) => {
      return response.json();
    })
    .catch((error) => {
      Sentry.captureException(error);
      console.error('通信に失敗しました', error);
    });
  return result;
};
